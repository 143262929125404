<style lang="scss" scoped>
  .privacy {
    h1 {
      font-family: "Circular Std";
      font-size: 1rem;
      line-height: 2;
      @media only screen and (min-width: 960px) {
        font-size: 1.333rem;
        line-height: 1.6;
      }
    }

    h2, h3, p, li, table tr td {
      font-size: 0.75rem;
      line-height: 1.6;
      margin: 1.25rem 0;
    }

    h3 {
      font-family: "Circular Std";
    }

    table tr td {
      padding: 0.75rem 0.25rem;
      @media only screen and (min-width: 960px) {
        padding: 0.75rem 1.5rem;
      }
    }
  }
</style>

<template>
  <Modal v-bind="$attrs" content-class="privacy">
    <h1>
      Privacy Policy
    </h1>
    <v-divider class="my-4" />

    <h2>What does Novus Home Mortgage, a division of Ixonia Bank, do with your personal information?</h2>

    <h3>Why are we sharing this?</h3>
    <p>Financial companies, including us, share some of your personal information to run their business. Federal law gives you the right to limit certain types of sharing, and also requires us to explain how we collect, share, and protect your information.</p>

    <h3>What information do we collect?</h3>
    <p>The types of personal information we collect and share depend on the product or service you have with us. This may include:</p>
    <ul>
      <li>Social Security number and income</li>
      <li>Credit history and credit scores</li>
      <li>Mortgage rates, payments, and account balances</li>
    </ul>

    <h3>What happens after you stop being a customer?</h3>
    <p>Even when you're no longer a customer, we may continue to share your information as described in this notice.</p>

    <h2>How We Use Your Information</h2>
    <p>We use your personal information to perform essential business functions, like processing transactions or reporting to credit bureaus. Below is a breakdown of how we may share your information, and whether you can control that sharing:</p>

    <h3>1. Everyday Business Purposes</h3>
    <p><strong>How it's used:</strong> To process your transactions, maintain accounts, and respond to legal orders (like court requests or credit bureau reports).<br>
    <strong>Do we share?</strong> Yes<br>
    <strong>Can you limit this sharing?</strong> No</p>

    <h3>2. Marketing Our Products and Services</h3>
    <p><strong>How it's used:</strong> To offer you new or updated products and services.<br>
    <strong>Do we share?</strong> Yes<br>
    <strong>Can you limit this sharing?</strong> No</p>

    <h3>3. Joint Marketing with Other Financial Companies</h3>
    <p><strong>How it's used:</strong> To market products or services in partnership with other financial companies.<br>
    <strong>Do we share?</strong> No<br>
    <strong>Can you limit this sharing?</strong> Not applicable (we don't share)</p>

    <h3>4. Sharing with Affiliates</h3>
    <p><strong>How it's used:</strong> Information sharing with related companies (affiliates) for business purposes or marketing.<br>
    <strong>Do we share?</strong> No<br>
    <strong>Can you limit this sharing?</strong> Not applicable (we don't share)</p>

    <h3>5. Sharing with Nonaffiliated Companies</h3>
    <p><strong>How it's used:</strong> To share your information with companies we’re not related to (nonaffiliates), for marketing purposes.<br>
    <strong>Do we share?</strong> No<br>
    <strong>Can you limit this sharing?</strong> Not applicable (we don't share)</p>

    <h2>Your Choices and Rights</h2>

    <h3>Why can’t I limit all sharing?</h3>
    <p>Federal law gives you the right to limit only:</p>
    <ul>
      <li>Sharing for affiliates' everyday business purposes (like creditworthiness)</li>
      <li>Affiliates using your information to market to you</li>
      <li>Sharing with nonaffiliates to market to you</li>
    </ul>
    <p>Some state laws or individual companies may give you additional rights to limit sharing.</p>

    <h3>How can you opt out of marketing or messages?</h3>
    <p>You can control the marketing messages you receive from us:</p>
    <ul>
      <li>To stop text messages, reply STOP to any text.</li>
      <li>If you no longer wish to receive calls or emails, visit our <a target="_blank" href="https://novushomemortgage.com/contact">contact page</a> to manage your preferences.</li>
    </ul>

    <h2>Security and Privacy</h2>

    <h3>How do we protect your information?</h3>
    <p>We take the security of your personal information seriously. Our measures include securing our buildings, digital safeguards, and regularly updating our practices to comply with federal law.</p>

    <h3>How do we collect your information?</h3>
    <p>We collect your information when you:</p>
    <ul>
      <li>Apply for a loan</li>
      <li>Provide income or employment details</li>
      <li>Give us your contact or mortgage information</li>
    </ul>
    <p>We may also collect information from credit bureaus or other financial companies.</p>

    <h2>Definitions</h2>

    <h3>Affiliates</h3>
    <p>Companies related by common ownership or control. Novus Home Mortgage does not share your information with our affiliates.</p>

    <h3>Nonaffiliates</h3>
    <p>Companies not related by common ownership or control. We do not share your information with nonaffiliates to market to you.</p>

    <h3>Joint Marketing</h3>
    <p>A formal agreement between nonaffiliated financial companies to market financial products or services together. Novus Home Mortgage does not participate in joint marketing.</p>

    <h2>Questions?</h2>
    <p>If you have any questions about this policy, you can:</p>
    <ul>
      <li>Call us at (844) 337-2548</li>
      <li>Email us through our <a target="_blank" href="https://novushomemortgage.com/contact">contact page</a></li>
    </ul>
  </Modal>
</template>
